import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Юридическая помощь | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Нужно представительство в суде или просто изучение составленного договора — стоит воспользоваться профессиональной помощью специалистов. " />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/yuridicheskaya-pomoshch'
				name="Юридическая помощь | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Нужно представительство в суде или просто изучение составленного договора — стоит воспользоваться профессиональной помощью специалистов. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Юридическая помощь" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Юридическая помощь"}
			
				html={

				`
				<p class="TextText">
					<b>Юридическая помощь в Казани</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юридическая помощь — то, без чего не может обойтись ни одно юридическое лицо. Нужно представительство в суде или просто изучение составленного договора — стоит воспользоваться профессиональной помощью специалистов. Это убережет от множества возможных проблем, поможет не ошибиться при заключении крупных сделок. Юридическая помощь юридическим лицам — то, от чего не стоит отказываться.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Как показывает практика, большинство процессов в жизни любой компании нужно проводить после осмотра и оценки документов опытным высококвалифицированным юристом. Юридические услуги — востребованная помощь, потому что помогает избежать существенных убытков. Какие юридические услуги юридическим лицам оказывает наша компания:
				</p>

				<p class="TextText">
					<b>Представительство в арбитражных судах и судах общей юрисдикции</b>
				</p>

				<p class="TextText">
					<b>Оспаривание крупных сделок и сделок с заинтересованностью</b>
				</p>

				<p class="TextText">
					<b>Разработка и правовая экспертиза юридических документов</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Хорошая репутация компании — то, что необходимо поддерживать. Без этого показателя очень сложно вести дела. Наша компания может гарантировать оказание юридических услугс должным качеством и быстротой.
				</p>

				<p class="TextText">
					<b>Юридические услуги в Казани стоит заказать в нашей компании, ведь наши специалисты:</b>
				</p>

				<p class="TextText">
					Обладают обширными знаниями
				</p>

				<p class="TextText">
					Имеют длительную и разностороннюю практику;
				</p>

				<p class="TextText">
					Имеют компетенцию в спорах разного уровня сложности
				</p>

				<p class="TextText">
					<b>Систематическое сопровождение</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юридические услуги предпринимателями организациям включают в себя не только разовые услуги, но и комплексное сопровождение по любому вопросу. Этот вариант сотрудничества показал себя весьма эффективным, ведь большинство юридических задач решали сотрудники нашей компании. Это может быть не просто составление искового заявления в суд, но и полное сотрудничество от момента заявления и до завершения дела.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юридические услуги предприятия включают в себя договоренности по ведению юридической составляющей жизни компании. Можно использовать любого нашего специалиста в качестве внештатного сотрудника:
				</p>

				<p class="TextText">
				Это выгодно — не нужно каждый месяц платить зарплату человеку, которого нечем занять на полный день
				</p>

				<p class="TextText">
				Это удобно — юрист нашей компании всегда на связи
				</p>

				<p class="TextText">
				Это комфортно — при каждом последующем составлении договора дело будет вести тот же человек, что и раньше, он в курсе всех дел вашей компании
				</p>

				<p class="TextText">
				Это просто — не нужно самостоятельно разбираться в завалах юридических тонкостей и бумагах
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Достаточно просто заключить договор и передать все заботы о сопровождении специалисту, который знает, что нужно делать. Юридические услуги в Казани — то, что легко получить в нашей компании. Стоит только набрать номер или обратиться по указанному на сайте адресу. И больше можно не переживать о правильности оформления любого документа
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
